import AddShiftRecordDTO from './addShiftRecordDTO'

export default class AddShiftRecordPayloadDTO extends AddShiftRecordDTO {
  constructor({
    bookingId,
    blocks = [],
    date = null,
    reasonForNonCompliance = '',
    isRestBreakCompliant,
    jsonExtraData,
  } = {}) {
    super({
      blocks,
      reasonForNonCompliance,
      isRestBreakCompliant,
      jsonExtraData,
    })

    /**
     * @type {Number}
     */
    this.bookingId = bookingId

    /**
     * Date the shift was held on. Used in toast notification on submit
     * @type {Date}
     */
    this.date = date
  }

  /**
   * Converts `AddShiftRecordPayloadDTO` into `AddShiftRecordDTO`
   * @returns {AddShiftRecordDTO}
   */
  toDto() {
    return new AddShiftRecordDTO({
      blocks: this.blocks,
      reasonForNonCompliance: this.reasonForNonCompliance,
      isRestBreakCompliant: this.isRestBreakCompliant,
      jsonExtraData: this.jsonExtraData,
    })
  }
}
