export default function parseJson(str, defaultValue = {}) {
  if (!str) return defaultValue

  const trimmedStr = str.trim()

  try {
    return JSON.parse(trimmedStr)
  } catch (e) {
    return defaultValue
  }
}
