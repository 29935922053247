/**
 * Some basic elements that make up an address
 */
export default class RegionViewModel {
  constructor({ country = '', state = '' } = {}) {
    /**
     * @type {String}
     */
    this.country = country

    /**
     * @type {String}
     */
    this.state = state
  }
}
