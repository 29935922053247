/* eslint-disable no-unused-vars */
import { HttpResponse } from '@root/node_modules/@aspnet/signalr/dist/esm/HttpClient'
import { ModelApiService } from './BaseApiService'
import TentativeFillRequestDTO from '@/models/booking/tentativeFillRequestDTO'
import BookingDetailsGuestAccessRequestDTO from '@/models/booking/bookingDetailsGuestAccessRequestDTO'
import TentativeFillRequestAnonymousDTO from '@/models/booking/tentativeFillRequestAnonymousDTO'
import CandidateBookingDetailsGuestAccessViewModel from '@/models/booking/candidateBookingDetailsGuestAccessViewModel'
import ResultDTO from '@/models/app/resultDTO'
import AddShiftRecordPayloadDTO from '@/models/booking/shiftRecord/addShiftRecordPayloadDTO'

export default class BookingsApiService extends ModelApiService {
  constructor() {
    super('bookings')
  }

  getOverviewBookingsInRange(dateFromLocal, dateToLocal) {
    return this.get('overview', {
      dateFromLocal,
      dateToLocal,
    })
  }

  getSummaryBookingsByDate(targetDateLocalYYYYMMDD) {
    return this.get(`summary/${targetDateLocalYYYYMMDD}`)
  }

  /**
   * Notifies the client that this candidate will be late to this booking
   * @param {Number} bookingId
   * @param {int} minutesLate
   */
  notifyRunningLate(bookingId, minutesLate) {
    return this.post(`${bookingId}/late`, { minutesLate })
  }

  /**
   * @param {TentativeFillRequestDTO} dto
   * @returns
   */
  tentativeFill(dto) {
    return this.post(`${dto.bookingId}/tentative-fill`, dto.payload)
  }

  /**
   * @param {TentativeFillRequestAnonymousDTO} dto
   * @returns
   */
  tentativeFillAnon(dto) {
    return this.postAnon(
      `${dto.shortTermTentativeId}/guest/${dto.accessGuid}/tentative-fill`,
      dto.payload
    )
  }

  /**
   * @param {TentativeFillRequestAnonymousDTO} dto
   * @returns
   */
  groupTentativeFillAnon(dto) {
    return this.postAnon(
      `group/guest/${dto.accessGuid}/tentative-fill`,
      dto.payload
    )
  }

  /**
   * @param {BookingDetailsGuestAccessRequestDTO} dto
   * @returns {Promise<CandidateBookingDetailsGuestAccessViewModel>}
   */
  getBookingByIdAnon(dto) {
    return this.getAnon(`${dto.shortTermTentativeId}/guest/${dto.accessGuid}`)
  }

  /**
   * @param {Guid} accessToken
   * @returns {Promise<CandidateBookingDetailsGuestAccessViewModel>}
   */
  getGroupBookingByIdAnon(accessToken) {
    return this.getAnon(`group/guest/${accessToken}`)
  }

  getBookingRelatedAgreements(bookingId) {
    return this.get(`${bookingId}/agreements`)
  }

  /**
   * Returns shift record details for a specific booking
   * @param {Number} bookingId
   * @returns {Promise<ResultDTO>}
   */
  getShiftRecordDetails(bookingId) {
    return this.get(`${bookingId}/shift-record`)
  }

  /**
   * Submits a shift record for a specific booking
   * @param {AddShiftRecordPayloadDTO} payload
   * @returns {Promise<ResultDTO>}
   */
  submitShiftRecord(payload) {
    return this.post(`${payload.bookingId}/shift-record`, payload.toDto())
  }

  /**
   * Sets the booking as did not happen
   * @param {Number} bookingId
   * @param {String} [didNotHappenResponse='']
   * @returns {Promise<ResultDTO>}
   */
  submitBookingDidNotHappen(bookingId, didNotHappenResponse) {
    const query = didNotHappenResponse
      ? `?didNotHappenResponse=${encodeURIComponent(didNotHappenResponse)}`
      : ''
    return this.delete(`${bookingId}${query}`)
  }
}
