/* eslint-disable no-unused-vars */
import ShiftRecordBlock from './shiftRecordBlock'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * Payload to add a shift record
 */
export default class AddShiftRecordDTO {
  constructor({
    blocks = [],
    reasonForNonCompliance = '',
    isRestBreakCompliant,
    jsonExtraData = '{}',
  } = {}) {
    /**
     * @type {Array<ShiftRecordBlock>}
     */
    this.blocks = isNonEmptyArray(blocks)
      ? blocks.map((block) => new ShiftRecordBlock(block))
      : []
    /**
     * A reason provided by the candidate as to why the shift record they are
     * submitting is non-compliant with regulations (typically local).
     * @type {String}
     */
    this.reasonForNonCompliance = reasonForNonCompliance

    /**
     * Flag for candidate to indicate if they received the appropriate amount of
     * rest breaks for their shift
     * @type {Boolean}
     */
    this.isRestBreakCompliant = isRestBreakCompliant

    /**
     * Additional data in JSON format for dynamic fields
     * @type {String}
     */
    this.jsonExtraData = jsonExtraData
  }
}
