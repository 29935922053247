import AddressViewModel from './addressViewModel'
import RegionViewModel from './regionViewModel'

/**
 * Some basic elements that make up an address
 */
export default class BaseLocationAddress {
  constructor({ address = {}, id, name, region } = {}) {
    /**
     * @type {Number}
     */
    this.id = id
    /**
     * Location Name
     * @type {String}
     */
    this.name = name
    /**
     * Location data from the region table for string consistency
     * @type {RegionViewModel}
     */
    this.region = new RegionViewModel(region)
    /**
     * @type {AddressViewModel}
     */
    this.address = new AddressViewModel(address)
  }
}
